import React, { useState } from 'react';
import '../css/Selector.css';
import { guestList } from '../State';
import { useRecoilValue } from 'recoil';
import { HiArrowNarrowRight } from 'react-icons/hi';
import EventManager from './EventManager';
import { Link } from 'react-router-dom';

const Selector = ({ email, onReset, onSubmit }) => {

    const list = useRecoilValue(guestList);
    const [guest, setGuest] = useState(null);
    const [attending, setAttending] = useState([]);
    const [submitted, setSubmitted] = useState(false);



    const handleSelect = (index) => {
        setGuest(list[index]);
    }

    const handleSubmitted = () => {
        setSubmitted(true);

        let data = {
            ...guest,
            attending: attending,
            rsvp: true
        }

        onSubmit(data);
    }

    const getNumberAttending = () => {
        let sum = 0;
        for (let i = 0; i < attending.length; i++) {
            sum += attending[i];
        }

        return sum;
    }

    const response = () => {
        if (submitted) {
            if (getNumberAttending() == 0) {
                return (
                    <p className="select-retry" >
                        We are sorry that you will not be able to attend our wedding in person. You are welcome to view the live stream of our wedding from the convenience of your home. <Link to="/">Home</Link>
                    </p>
                );
            } else {
                return (
                    <p className="select-retry" >
                        Thank you for your RSVP! We look forward to Celebrating our big day with you. <Link to="/">Home</Link>
                    </p>
                )
            }
        }
        return null;
    }

    const isInList = () => {
        for (let i = 0; i < list.length; i++) {
            if (email.toLowerCase() === list[i].email.toLowerCase()) return true;
            if (email === list[i].phone) return true;
        }
        return false;
    }

    return (
        <div className="selector-container">
            {guest === null && isInList() ? <>
                <h3>Select your name below</h3>
                {list.map((item, index) => {

                    if (email.toLowerCase() !== item.email.toLowerCase() && email !== item.phone) return;
                    return (
                        <>
                            <div className="selectable" key={index} onClick={() => handleSelect(index)}>
                                <p className="head">{item.name}</p>
                                {item.email ? <p className="tail">{item.email}</p> : <p className="tail">{item.phone}</p>}
                                <HiArrowNarrowRight className="arrow" />
                            </div>
                            <p className="select-retry" >
                                Cant find your name? <span onClick={onReset}>Try again</span>
                            </p>
                        </>
                    )
                })}
            </> : null}

            {guest !== null && !submitted ?
                <>
                    <EventManager
                        onChange={(a) => setAttending(a)}
                    />
                    <button onClick={handleSubmitted}>SUBMIT</button>
                </> : null
            }

            {!isInList() ?
                <p>Email Not found. <Link to='/'>Home</Link></p>
                :
                null
            }


            {response()}



        </div>
    );
}

export default Selector;
