import React, { useState, useEffect } from 'react';
import '../css/Admin.css';
import { getAuth, getDatabase } from '../api/Firebase';
import { guestList } from '../State';
import { useRecoilState } from 'recoil';
import InviteForm from '../components/InviteForm';
import { CSVLink, CSVDownload } from 'react-csv';


const Admin = () => {
    //login form
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    //add guest
    const [visible, setVisible] = useState(false);
    const [disableEmail, setDisableEmail] = useState(false);
    const [viewResponses, setViewResponses] = useState(false);

    //global guest list
    const [guests, setGuests] = useRecoilState(guestList);

    //edit
    const [editIndex, setEditIndex] = useState(-1);
    const [editName, setEditName] = useState("");
    const [editEmail, setEditEmail] = useState("");
    const [editAttending, setEditAttending] = useState("");
    const [editPhone, setEditPhone] = useState("");

    //on component mount
    useEffect(() => {
        addSignInObserver();

    }, []);

    //on user logged in
    useEffect(() => {
        if (loggedIn) {
            return subscribeToList();
        }
    }, [loggedIn])

    const update = (e) => {
        if (e.target.name === 'email') {
            setEmail(e.target.value);
        }
        if (e.target.name === 'password') {
            setPassword(e.target.value);
        }
        setError("");
    }

    const updateEdit = (e) => {
        if (e.target.placeholder === 'Edit name') {
            setEditName(e.target.value);
        }
        if (e.target.placeholder === 'Edit email') {
            setEditEmail(e.target.value);
        }
        if (e.target.name === 'e1') {
            parseEditArray(0, e.target.value, 'edit');
        }
        if (e.target.name === 'e2') {
            parseEditArray(1, e.target.value, 'edit');
        }
        if (e.target.name === 'e3') {
            parseEditArray(2, e.target.value, 'edit');
        }
        if (e.target.name === 'e4') {
            parseEditArray(3, e.target.value, 'edit');
        }
        if (e.target.name === "iphone") {
            setEditPhone(e.target.value);
        }
    }

    const parseEditArray = (index, value, type) => {
        let v;
        if (isNaN(value)) {
            v = '';
        } else {
            v = parseInt(value);
        }

        let a = [...editAttending];
        a[index] = v;
        if (type === 'edit') {
            setEditAttending(a);
        }

    }

    const addSignInObserver = () => {
        let auth = getAuth();
        auth.onAuthStateChanged(user => {
            if (user) {
                console.log('user signed in');
                setLoggedIn(true);
            } else {
                console.log('user logged out');
                setLoggedIn(false);
            }
        });
    }

    const login = () => {
        let auth = getAuth();
        auth.signInWithEmailAndPassword(email, password)
            .then((userCred) => {
                setLoggedIn(true);
            }).catch(error => {
                setError(`error: ${error.code}; message: ${error.message}`);
                console.error(error);
            })
    }

    const subscribeToList = () => {
        var unsub = getDatabase().collection("guests").orderBy("name")
            .onSnapshot(query => {
                let list = [];
                query.forEach(doc => {
                    list.push({ ...doc.data(), id: doc.id });
                });
                setGuests(list);
            });

        return unsub;
    }

    const edit = index => {
        setEditIndex(index);
        setEditName(guests[index].name);
        setEditEmail(guests[index].email);
        setEditAttending(guests[index].attending ? guests[index].attending : [0, 0, 0, 0]);
        setEditPhone(guests[index].phone);
    }

    const save = (index) => {
        let data = {
            name: editName,
            email: editEmail,
            phone: editPhone,
            attending: editAttending
        };

        if (editName.trim() === '' || (editEmail.trim() === '' && editPhone.trim() === '')) {
            return;
        }

        let db = getDatabase();
        db.collection('guests').doc(guests[index].id)
            .update(data).then(() => {
                setEditIndex(-1);
            })


    }

    const remove = (index) => {

        let db = getDatabase();
        db.collection('guests').doc(guests[index].id)
            .delete().then(() => {
                setEditIndex(-1);
            })


    }

    const onAddClose = () => {
        setVisible(false);
        setDisableEmail(false);
    }

    const getEventTotal = (index) => {
        if (!guests) return;

        //console.log(guests);

        let total = 0;
        for (let i = 0; i < guests.length; i++) {

            if (!guests[i].attending) continue;
            if (isNaN(parseInt(guests[i].attending[index]))) continue;
            total += guests[i].attending[index];

        }
        return total;
    }

    const getRsvpTotal = () => {
        if (!guests) return;

        let total = 0
        for (let i = 0; i < guests.length; i++) {
            if (guests[i].rsvp) total++;
        }

        return total;
    }

    const addGuest = () => {
        setDisableEmail(true);
        setVisible(true);

    }

    const exportCSV = () => {
        let result = [];
        for (let i = 0; i < guests.length; i++) {
            // console.log("guests: ", guests);
            result.push({
                ...guests[i],
                "Mehendi & Sangeet": guests[i].attending ? guests[i].attending[0] : 0,
                "Haldi Churra": guests[i].attending ? guests[i].attending[1] : 0,
                "Anand Karaj": guests[i].attending ? guests[i].attending[2] : 0,
                "Reception": guests[i].attending ? guests[i].attending[3] : 0
            })
        }

        return result;
    }


    return (
        <div className="admin-container">

            <InviteForm
                visible={visible}
                onClose={onAddClose}
                disabled={disableEmail}
            />

            {loggedIn ? <div className="admin-button-control">
                <button onClick={() => addGuest()} className="invite-button">Add Guest</button>
                <button onClick={() => setVisible(true)} className="invite-button">New Invite</button>
                <button onClick={() => setViewResponses(!viewResponses)} className="invite-button">{viewResponses ? 'Hide ' : 'View '} Responses</button>
                <CSVLink data={exportCSV()} className="invite-button">Export</CSVLink>
            </div> : null
            }

            {
                !loggedIn ? <div className="admin-login">
                    <h1>Login</h1>
                    <label htmlFor="email">Email</label>
                    <input autoComplete="off" type="text" name="email" onChange={update} value={email} />
                    <label htmlFor="password">Password</label>
                    <input autoComplete="off" type="password" name="password" onChange={update} value={password} />
                    <button onClick={login}>Login</button>
                    <p>{error}</p>
                </div> : null
            }

            {
                loggedIn && viewResponses ?
                    <>
                        <div className="table-wrapper">
                            <table className="admin-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Phone
                                        </th>
                                        <th>
                                            {"Mehendi Sangeet"}
                                            <span class="running-total">{getEventTotal(0)}</span>
                                        </th>
                                        <th>
                                            {"Haldi Churra"}
                                            <span class="running-total">{getEventTotal(1)}</span>
                                        </th>
                                        <th>
                                            {"Anand Karaj"}
                                            <span class="running-total">{getEventTotal(2)}</span>
                                        </th>
                                        <th>
                                            {"Reception"}
                                            <span class="running-total">{getEventTotal(3)}</span>
                                        </th>
                                        <th>
                                            RSVP
                                            <span class="running-total">{getRsvpTotal()}</span>
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {guests.map((item, index) => {
                                        return (
                                            editIndex !== index ? <tr key={index}>
                                                <td data-label="Name"  >{item.name}</td>
                                                <td data-label="Email">{item.email}</td>
                                                <td data-label="Phone">{item.phone}</td>
                                                <td data-label="Mehendi & Sangeet" total={getEventTotal(0)}>{item.attending ? item.attending[0] : 0}</td>
                                                <td data-label="Haldi & Churra" total={getEventTotal(1)}>{item.attending ? item.attending[1] : 0}</td>
                                                <td data-label="Anand Karaj" total={getEventTotal(2)}>{item.attending ? item.attending[2] : 0}</td>
                                                <td data-label="Reception" total={getEventTotal(3)}>{item.attending ? item.attending[3] : 0}</td>
                                                <td data-label="RSVP" total={getRsvpTotal()}>{item.rsvp ? 'true' : 'false'}</td>
                                                <td data-label="Action"><span onClick={() => edit(index)}>Edit</span></td>
                                            </tr>
                                                :
                                                <tr key={index}>
                                                    <td data-label="Name" >
                                                        <textarea placeholder="Edit name" value={editName} onChange={updateEdit} />
                                                    </td>
                                                    <td data-label="Email">
                                                        <textarea placeholder="Edit email" value={editEmail} onChange={updateEdit} />
                                                    </td>
                                                    <td data-label="Phone">
                                                        <textarea name="iphone" placeholder="Edit phone" value={editPhone} onChange={updateEdit} />
                                                    </td>
                                                    <td data-label="Mehendi & Sangeet">
                                                        <input type="number" placeholder="#" name="e1" value={editAttending[0]} onChange={updateEdit} />
                                                    </td>
                                                    <td data-label="Haldi & Churra">
                                                        <input type="number" placeholder="#" name="e2" value={editAttending[1]} onChange={updateEdit} />
                                                    </td>
                                                    <td data-label="Anand Karaj">
                                                        <input type="number" placeholder="#" name="e3" value={editAttending[2]} onChange={updateEdit} />
                                                    </td>
                                                    <td data-label="Reception">
                                                        <input type="number" placeholder="#" name="e4" value={editAttending[3]} onChange={updateEdit} />
                                                    </td>
                                                    <td data-label="RSVP">
                                                        None
                                                    </td>
                                                    <td data-label="Action" className="action"><span onClick={() => save(index)}>Save</span> <span onClick={() => remove(index)}>Delete</span></td>
                                                </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>

                    </>
                    :
                    null
            }


        </div >
    );
}

export default Admin;