import React, { useState, useEffect } from 'react';
import '../css/Navbar.css';
import { Link } from 'react-router-dom';
import { IoMenuSharp } from 'react-icons/io5';
import { useLocation } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { guestList } from '../State';
import { RecoilRoot } from 'recoil';
import { getDatabase } from '../api/Firebase';

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [homeClass, setHomeClass] = useState("home-link");
    const [travelClass, setTravelClass] = useState("");
    const [rsvpClass, setrsvpClass] = useState("");
    const [galleryClass, setGalleryClass] = useState("");

    const setGuestList = useSetRecoilState(guestList);

    let location = useLocation();

    const toggleOpen = () => {
        setOpen(!open);
    }

    const handleClick = () => {
        toggleOpen();
    }

    const closeMenu = () => {
        setOpen(false);
    }

    const setActiveLink = () => {
        let path = location.pathname

        if (path === '/') {
            setHomeClass("home-link active");
            setTravelClass("");
            setrsvpClass("");
            setGalleryClass("");
        } else if (path === '/travel') {
            setHomeClass("home-link");
            setTravelClass("active");
            setrsvpClass("");
            setGalleryClass("");
        } else if (path === '/rsvp') {
            setHomeClass("home-link");
            setTravelClass("");
            setrsvpClass("active");
            setGalleryClass("");
        } else if (path === '/gallery') {
            setHomeClass("home-link");
            setTravelClass("");
            setrsvpClass("");
            setGalleryClass("active");
        }
    }

    useEffect(() => {
        setActiveLink();
    }, [location]);

    useEffect(() => {
        initGuestList();
    }, []);

    const initGuestList = () => {
        let db = getDatabase();

        db.collection("guests").get().then(query => {
            let list = [];
            query.forEach(doc => {
                list.push({ ...doc.data(), id: doc.id });
            })
            setGuestList(list);
            //console.log(list);
        })
    }


    return (
        <div className={open ? "nav-container nav-open" : "nav-container"}>
            <h2>S&M</h2>
            <Link className={open ? homeClass : "nav-closed " + homeClass} to="/" onClick={closeMenu}>Home</Link>
            <Link className={open ? travelClass : "nav-closed " + travelClass} to="/travel" onClick={closeMenu}>Travel</Link>
            <Link className={open ? galleryClass : "nav-closed " + galleryClass} to="/gallery" onClick={closeMenu}>Gallery</Link>
            {/* <Link className={open ? rsvpClass : "nav-closed " + rsvpClass} to="/rsvp" onClick={closeMenu}>RSVP</Link> */}

            <IoMenuSharp className="nav-icon" onClick={handleClick} />
        </div>
    );
}

export default Navbar;