import React, { useState, useEffect } from 'react';
import styles from "../css/Lightbox.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GrLinkNext, GrLinkPrevious, GrClose } from 'react-icons/gr';
import Placeholder from '../images/no-image.jpg'

const Lightbox = ({ urls }) => {


    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(0);
    const [previewIndex, setPreviewIndex] = useState(0);

    useEffect(() => {

        loadImages();


    }, [urls]);


    const nextImage = (e) => {
        setSelectedImage(prev => {
            if (prev + 1 > images.length - 1) {
                setPreviewIndex(0);
                return 0;
            } else {
                if ((prev + 1) - previewIndex > 4) {
                    setPreviewIndex(prev + 1);
                }
                return prev + 1;
            }
        });

        e.stopPropagation();
    }

    const prevImage = (e) => {
        setSelectedImage(prev => {
            if (prev - 1 < 0) {
                let pre = images.length % 5;
                let prei = pre == 0 ? 5 : pre;
                setPreviewIndex(images.length - prei);

                return images.length - 1;
            } else {
                if ((prev - 1) < previewIndex) {
                    setPreviewIndex(prev - 5);
                }
                return prev - 1;
            }
        });

        e.stopPropagation();
    }

    const chooseIndex = (event, index) => {
        if (index > images.length) {
            // setSelectedImage(index - images.length);
            // setPreviewIndex(0);
        }
        setSelectedImage(index);

        event.stopPropagation();
    }

    const loadImages = () => {
        let list = [];
        for (let obj of urls) {
            let i = <LazyLoadImage
                src={obj.url}
                effect="blur"
                placeholderSrc={Placeholder}
            />;
            list.push(i);
        }
        setImages(list);
    }

    const getPreviewImage = (startingIndex) => {

        let list = [];
        for (let i = 0; i < 5; i++) {
            if (startingIndex + i > images.length - 1) {
                let leftOverIndex = startingIndex + i - (images.length - 1);
                // list.push(images[leftOverIndex]);
                list.push('');
            } else {
                list.push(images[startingIndex + i]);
            }
        }

        return list;
    }

    const stopProp = e => {
        e.stopPropagation();
    }


    return (
        <div className={styles.container} onClick={e => stopProp(e)} >
            <div className={styles.next} onClick={(e) => nextImage(e)}>
                <GrLinkNext />
            </div>

            <div className={styles.previous} onClick={(e) => prevImage(e)}>
                <GrLinkPrevious />
            </div>
            <div className={styles.main} >

                {
                    images[selectedImage]
                }

            </div>
            <div className={styles.picker}>

                {
                    getPreviewImage(previewIndex).map((img, index) => {
                        return (
                            <div onClick={(event) => chooseIndex(event, index + previewIndex)} key={index} className={index == (selectedImage % 5) ? `${styles.preview} ${styles.selected}` : styles.preview}>
                                {img}
                            </div>
                        )
                    })
                }


            </div>
        </div>
    );
}

export default Lightbox;