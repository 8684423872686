import React, { useState, useEffect } from 'react';
import '../css/Gallery.css';
import axios from 'axios';
import GalleryFolder from '../components/GalleryFolder';
import ReactPlayer from 'react-player'


const Gallery = () => {
    const [images, setImages] = useState([]);
    const [group1, setGroup1] = useState([]);
    const [group2, setGroup2] = useState([]);
    const [group3, setGroup3] = useState([]);
    const [group4, setGroup4] = useState([]);
    const [group5, setGroup5] = useState([]);
    const [group6, setGroup6] = useState([]);

    const [all, setAll] = useState([]);


    useEffect(() => {

        // getUrls("invitation").then(arr => {
        //     setImages(arr);
        // });

        // getUrls("Chooda Ceremony").then(arr => {
        //     setGroup1(arr);
        // });

        getUrls("Haldi").then(arr => {
            setGroup2(arr);
        });

        getUrls("Mehendi").then(arr => {
            setGroup3(arr);
        });

        getUrls("Engagement").then(arr => {
            setGroup4(arr);
        });

        getUrls("Wedding").then(arr => {
            setGroup5(arr);
        });

        getUrls("Reception").then(arr => {
            setGroup6(arr);
        });

        // getAllUrls().then(arr => {
        //     setAll(arr);
        // })

    }, []);

    const getUrls = async (folder) => {
        const res = await axios({
            method: 'post',
            url: 'https://makeiteasy.co.nz/wp-json/gallery/wedding',
            data: {
                folder: folder,
            }
        });

        console.log('res:', res);

        let files = res.data.files;
        let url = res.data.url;

        let arr = [];
        for (let file of files) {
            if (file === "." || file === "..") continue;
            arr.push({
                url: url + "/" + file,
                index: parseInt(file.replace(/[A-Za-z\.]/g, ""))
            });
        }

        arr.sort((a, b) => sortUrl(a, b));
        console.log('arr', arr);

        return arr;
    }

    const getAllUrls = async () => {
        let folders = ['Chooda Ceremony', 'Haldi', 'Mehendi', 'Engagement'];

        let final = [];
        for (let name of folders) {
            let arr = await getUrls(name);

            final = [...final, ...arr];
        }
        return final;
    }

    const sortUrl = (a, b) => {
        if (a.index < b.index) {
            return -1;
        } else if (a.index > b.index) {
            return 1;
        } else {
            return 0;
        }
    }

    return (
        <div className="gallery-container">

            {/* <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/-Fsphik0VSo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
            </iframe> */}

            <div className='video-container' >
                <ReactPlayer
                    url='https://www.youtube.com/watch?v=-Fsphik0VSo'
                    playing={true}
                    controls={true}
                    style={{ maxWidth: '100%' }}
                />
            </div>

            <GalleryFolder images={group4} title="Kurmai (Engagement)" />
            <GalleryFolder images={group3} title="Mehendi & Sangeet" />
            <GalleryFolder images={group2} title="Haldi & Churra" />
            <GalleryFolder images={group5} title="Anand Karaj (Wedding)" />
            <GalleryFolder images={group6} title="Reception" />



        </div>
    );
}

export default Gallery;
