import React, { useState, useEffect } from 'react';
import '../css/Footer.css';
import { Link, useLocation } from 'react-router-dom';
import { IoSettingsOutline } from 'react-icons/io5';

const Footer = () => {
    const [homeClass, setHomeClass] = useState("");
    const [travelClass, setTravelClass] = useState("");
    const [rsvpClass, setrsvpClass] = useState("");
    const [galleryClass, setGalleryClass] = useState("");

    let location = useLocation();

    useEffect(() => {
        setActiveTab();
    }, []);

    const setActiveTab = () => {
        let path = location.pathname;

        if (path === '/travel') {
            setHomeClass("");
            setTravelClass("active");
            setrsvpClass("");
        } else if (path === '/rsvp') {
            setHomeClass("");
            setTravelClass("");
            setrsvpClass("active");
        } else if (path === '/gallery') {
            setHomeClass("");
            setTravelClass("");
            setrsvpClass("");
            setGalleryClass("");
        } else {
            setHomeClass("active");
            setTravelClass("");
            setrsvpClass("");
        }
    }


    return (
        <div className="footer-container">

            {/* <div className="footer-nav">
                <span>
                    <Link className={homeClass} to="/" >Home</Link>
                </span>
                <span>
                    <Link className={travelClass} to="/travel" >Travel</Link>
                </span>
                <span>
                    <Link className={galleryClass} to="/gallery" >Gallery</Link>
                </span>
                <span>
                    <Link className={rsvpClass} to="/rsvp" >RSVP</Link>
                </span>


            </div> */}

            <h2>#Sushandvi</h2>


            <Link to="/admin" className="footer-admin"><IoSettingsOutline /></Link>
        </div>
    );
}

export default Footer;