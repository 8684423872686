import React, { useEffect } from 'react';
import '../css/Home.css';
import Event from '../components/Event';
import Footer from '../components/Footer';
import { useHistory } from 'react-router';

const Home = () => {

    let history = useHistory();

    //redirect to gallery 
    useEffect(() => {
        history.push('/gallery');
    })

    return (
        <div className="home-container">
            <div className="home-greeting">
                <div className="home-left">
                    <h2>May 23<sup>rd</sup> to 29<sup>th</sup>, 2022</h2>
                </div>
                <div className="home-right">
                    <h2>Auckland <br /> New Zealand</h2>
                </div>
            </div>
            <button onClick={() => history.push("/rsvp")}>RSVP</button>

            <Event
                title="Akhand Paath"
                day="23rd to 25th May 2022"
                venueTitle="Takanini Gurdwara Sri Kalgidhar Sahib"
                venue="70 Takanini School Road, Takanini"
                link="https://goo.gl/maps/amDWVCAGm9NucvfT9"
            />

            <Event
                title="Kurmai (Engagement)"
                day="7:00pm | 25th May 2022"
                venueTitle="Sushandvi Residence"
                venue="263 Flat Bush School Rd Auckland"
                link="https://goo.gl/maps/RBVVS3EQCcsubSHW8"
            />

            <Event
                title="Mehendi & Sangeet"
                day="7:00pm | 26th May 2022"
                venueTitle="Rongomai Sports Centre"
                venue="238 Te Irirangi Drive,  Auckland"
                link="https://goo.gl/maps/ubgfR7Xy6ynqxQmF7"
            />

            <Event
                title="Haldi & Churra"
                day="12:00pm | 27th May 2022"
                venueTitle="Rongomai Sports Centre"
                venue="238 Te Irirangi Drive, Auckland"
                link="https://goo.gl/maps/jT3LxRMcGQGKHvak8"
            />

            <Event
                title="Anand Karaj (Wedding)"
                day="10:00am | 28th May 2022"
                venueTitle="Takanini Gurdwara Sri Kalgidhar Sahib"
                venue="70 Takanini School Road, Takanini"
                link="https://goo.gl/maps/amDWVCAGm9NucvfT9"
            />

            <Event
                title="Reception"
                day="5:00pm | 29th May 2022"
                venueTitle="Karaka Pavilion"
                venue="10 Hinau Road, Karaka"
                link="https://goo.gl/maps/zd9s9prE53UkgnHB8"
            />

            <Footer />

        </div>
    );
}

export default Home;