import React, { useState } from 'react';
import { getDatabase } from '../api/Firebase';
import '../css/Respond.css';
import EmailForm from '../components/EmailForm';
import Selector from '../components/Selector';

const Respond = () => {
    const [email, setEmail] = useState("");


    const updateDatabase = (data) => {
        let db = getDatabase();
        let ref = db.collection("guests").doc(data.id);

        ref.update(data)
            .then(() => {
                console.log("update successful: ", data);
            }).catch(error => {
                console.error("Error updating document: ", error);
            });
    }

    return (
        <div className="respond-container">
            {email === "" ? <EmailForm
                onSubmit={setEmail}
            /> : <Selector
                email={email}
                onReset={() => setEmail("")}
                onSubmit={updateDatabase}
            />}

        </div>
    );
}

export default Respond;