import './App.css';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import headBanner from './images/banner-hi-res.jpg';
import smallBanner from './images/banner-med-res.jpg';
import footerBanner from './images/footer-hi-res.jpg';
import smallFooterBanner from './images/footer-med-res.jpg';
import Navbar from './components/Navbar';
import Home from './screens/Home';
import Travel from './screens/Travel';
import Respond from './screens/Respond';
import Admin from './screens/Admin';
import moment from 'moment';
import TestPage from './screens/TestPage';
import Gallery from './screens/Gallery';

import { useLocation } from 'react-router-dom';

function App() {

  const getDaysTillDay = () => {
    let wedding = moment('2021-12-11');
    let today = moment();
    return wedding.diff(today, 'days');

  }

  const location = useLocation();
  const [isHome, setIsHome] = useState(true);

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [location]);

  return (

    <RecoilRoot>
      <Switch>
        <div className="App">

          <picture className="headImg">
            <source srcSet={smallBanner} media="(max-width: 770px)" />
            <img src={headBanner} />
          </picture>

          <div className="main-wrapper">
            <div className="title">
              {isHome ? <h1>Sushant & Mandvi</h1> : <h1>#SushandviWedding</h1>}
              {isHome ? <p>Invite you and your family to celebrate their marriage</p> : null}

            </div>
            <Navbar />
            <Route path="/" exact component={Home} />
            <Route path="/travel" component={Travel} />
            <Route path="/rsvp" component={Respond} />
            <Route path="/admin" component={Admin} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/test" component={TestPage} />
          </div>

          <picture className="footImg">
            <source srcSet={smallFooterBanner} media="(max-width: 770px)" />
            <img src={footerBanner} />
          </picture>

        </div>
      </Switch>
    </RecoilRoot>


  );
}

export default App;
