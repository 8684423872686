import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../css/Travel.css';

const Travel = () => {

    let history = useHistory();

    //redirect to gallery 
    useEffect(() => {
        history.push('/gallery');
    })

    return (
        <div className="travel-container">
            <p className="travel-header">For your convenience we recommend the following overnight accommodation options:</p>
            <a href="https://www.flatbushaccommodation.co.nz/" target="_blank">
                <p className="travel-title">Flat Bush Holiday</p>
                <p className="travel-subtitle">www.flatbushaccomodation.co.nz</p>
            </a>

            <a href="https://www.hilton.com/en/hotels/aklkadi-doubletree-karaka/" target="_blank">
                <p className="travel-title">DoubleTree By Hilton Karaka</p>
                <p className="travel-subtitle">www.hilton.com</p>
            </a>


        </div>
    );
}

export default Travel;