import React, { useState, useEffect } from 'react';
import '../css/EmailForm.css';

const EmailForm = ({ onSubmit }) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('email')) {
            setEmail(params.get('email'));
        }
    })

    const updateEmail = (e) => {
        setEmail(e.target.value);
        setError("");
    }

    const submit = () => {
        if (!validateEmail(email)) {
            setError("Invalid Email or Phone Number");
            return;
        }
        onSubmit(email);
        setEmail("");
    }

    const validateEmail = (email) => {
        // var re = /\S+@\S+\.\S+/;
        // return re.test(email);
        if (email.trim() === '') return false;
        return true;
    }

    return (
        <div className="form-container">
            <label htmlFor="email">Enter your email or phone number to RSVP</label>
            <input type="email" name="email" onChange={updateEmail} value={email} autoComplete="off" />
            <button onClick={submit}>Go</button>
            <p className="error">{error}</p>
        </div>
    );
}

export default EmailForm;