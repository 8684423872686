import React from 'react';
import '../css/TestPage.css';
import { useHistory } from 'react-router';
import Event from '../components/Event';
import Footer from '../components/Footer';
import smallBanner from '../images/banner-med-res.jpg';
import headBanner from '../images/banner-hi-res.jpg';



const TestPage = () => {

    let history = useHistory();

    return (
        <div className="test-container">
            <picture className="headImg">
                <source srcSet={smallBanner} media="(max-width: 770px)" />
                <img src={headBanner} />
            </picture>
            <div className="test-main-content">
                <div className="home-container">
                    <div className="home-greeting">
                        <div className="home-left">
                            <h2>December 9-12, 2021</h2>
                        </div>
                        <div className="home-right">
                            <h2>Auckland New Zealand</h2>
                        </div>
                    </div>
                    <button onClick={() => history.push("/rsvp")}>RSVP</button>

                    <Event
                        title="Mehendi & Sangeet"
                        day="Thursday, December 9, 2021"
                        time="18:00-23:30"
                        venue={`Sushandavi Residence \n  263 Flat Bush School Rd, Auckland, 2019, New Zealand`}
                        attire="Colorful"
                        description="Night of Mehendi, Song and Dance"
                    />

                    <Event
                        title="Haldi and Chhodda Ceremony"
                        day="Friday, December 10, 2021"
                        time="12:00–18:00"
                        venue={`Sushandavi Residence \n  263 Flat Bush School Rd, Auckland, 2019, New Zealand`}
                        attire="Whatever you Fancy...be ready to get some Haldi on it"
                        description={`Haldi is Applied to the Bride and Groom and then to the rest of the guests. \n Brides Mama's present the wedding Chhodda to the Bride`}
                    />

                    <Event
                        title="Anand Karaj - The Big Day"
                        day="Saturday, December 11, 2021"
                        time="10:00–14:00"
                        venue={`Takanini Gurdwara Sri Kalgidhar Sahib \n  70 Takanini School Road, Takanini, Auckland, 2112, New Zealand`}
                        attire="Indian"
                        description={`Wedding day at the Gurudawara`}
                    />

                    <Event
                        title="Reception"
                        day="Sunday, December 12, 2021"
                        time="17:00–23:30"
                        venue={`Karaka Pavilion \n10 Hinau Road, Karaka, Auckland, 2113, New Zealand`}
                        attire="Formal"
                        description={`Wedding Reception...the After Party`}
                    />

                    <Footer />

                </div>
            </div>
        </div>
    );
}

export default TestPage;