import React, { useState, useEffect } from 'react';
import '../css/InviteForm.css';
import { guestList } from '../State';
import { useRecoilValue } from 'recoil';
import { AiFillCloseCircle } from 'react-icons/ai';
import { getDatabase } from '../api/Firebase';
import axios from 'axios';

const InviteForm = ({ visible, onClose, disabled }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const guests = useRecoilValue(guestList);

    const update = (e) => {
        if (e.target.name === 'iname') {
            setName(e.target.value);
        }
        if (e.target.name === 'iemail') {
            setEmail(e.target.value);
        }

        if (e.target.name === 'iphone') {
            setPhone(e.target.value);
        }
        setError("");
        setSuccess("");
    }

    const validateEmail = (email, phone) => {
        var re = /\S+@\S+\.\S+/;
        var valid = re.test(email);

        if (!valid && phone.trim() === '') {
            return false;
        }

        return true;
    }

    const onInvite = () => {

        if (name.trim() === '') {
            setSuccess("");
            setError('Invalid name');
            return;
        }

        if (!validateEmail(email, phone)) {
            setSuccess("");
            setError("invalid email address");
            return;
        }

        let invalid = false;
        guests.forEach(guest => {
            if (email.toLocaleLowerCase() === guest.email.toLocaleLowerCase() && email.trim() !== '') {
                setError(email.toLocaleLowerCase() + ' already invited');
                invalid = true;
            }
        });

        if (invalid) return;
        add();
    }

    const add = () => {
        let data = {
            name: name,
            email: email,
            phone: phone,
            invited: true,
        }

        let db = getDatabase();
        db.collection("guests").add(data)
            .then(() => {
                if (!disabled) {
                    sendEmail(data);
                } else {
                    setName("");
                    setEmail("");
                    setSuccess(data.email + " added to database");
                }
            }).catch(e => {
                console.error(e);
                setSuccess("");
                setError("Error: ", e.message);
            })
    }

    const sendEmail = (data) => {
        axios.post('https://sushandvi.com/invite/', data)
            .then(res => {
                setName("");
                setEmail("");
                setSuccess(res.data);
                console.log(res);
            }).catch(e => {
                console.error(e);
                setError("error sending invite");
            })
    }


    return (
        <div className={visible ? "invite-container" : "invite-container hidden"}>

            <div className="invite-form">
                <AiFillCloseCircle
                    className="close-icon"
                    onClick={onClose}
                />
                <label htmlFor="iname">Name:</label>
                <input name="iname" type="text" value={name} onChange={update} />
                <label htmlFor="iemail">Email:</label>
                <input name="iemail" type="email" value={email} onChange={update} />
                {disabled && <label htmlFor="iphone">Phone:</label>}
                {disabled && <input name="iphone" type="text" value={phone} onChange={update} />}
                <button onClick={onInvite}>{disabled ? "Add Guest" : "Invite Guest"}</button>
                <p className={success !== "" ? "success" : ""} >{error !== "" ? error : success}</p>
            </div>
        </div>
    );
}

export default InviteForm;