import React from 'react';
import '../css/Event.css';

const Event = ({ title, day, venueTitle, venue, link }) => {
    return (
        <div className="event-container">
            <p className="event-title">
                {title}
            </p>
            <p className="event-day">
                {day}
            </p>
            <p className="event-venue-title">
                <a href={link} target="_blank">{venueTitle}</a>
            </p>
            <p className="event-venue">
                <a href={link} target="_blank" >{venue}</a>
            </p>

        </div>
    );
}

export default Event;