// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyAPhZXYXBuNTHlRT8XMFZv9HxYJVcJ51EI",
    authDomain: "sushant-wedding.firebaseapp.com",
    projectId: "sushant-wedding",
    storageBucket: "sushant-wedding.appspot.com",
    messagingSenderId: "780687590616",
    appId: "1:780687590616:web:82009182a0d4521e0c83ad",
    measurementId: "G-N34FLN34EX"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export function getDatabase() {
    return firebase.firestore();
}

export function getAuth() {
    return firebase.auth();
}

export function getStorage() {
    return firebase.storage();
}

