import React, { useState } from 'react';

import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';
import { GrLinkNext, GrLinkPrevious, GrClose } from 'react-icons/gr';
import Lightbox from '../components/Lightbox';


const GalleryFolder = ({ images, title }) => {

    const [inv, setInv] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const incrementIndex = () => {
        setCurrentIndex(prevIndex => {
            if (prevIndex + 4 > images.length) return 0;
            return prevIndex + 4;
        })
    }

    const closeClick = (e) => {
        console.log('Close click', e);
        setInv(prev => !prev);
    }

    return (
        <>
            <div className='gallery-folder-title' onClick={() => setInv(prev => !prev)} >
                {inv ? <AiFillCaretDown /> : <AiFillCaretRight />}
                <h3 >{title}</h3>
            </div>
            <div className={inv ? "gallery-folder" : "gallery-folder hidden"}  >


                < div className="gallery-inner-folder">
                    <div className='gallery-icon close' onClick={closeClick}>
                        <GrClose />
                    </div>
                    <Lightbox
                        urls={images}
                    />
                </div>



            </div>
        </>
    );
}

export default GalleryFolder;