import React, { useState, useEffect } from 'react';
import '../css/EventManager.css';
import { GrFormAdd } from 'react-icons/gr';
import { FiMinus } from 'react-icons/fi';

const EventManager = ({ onChange }) => {

    const [counters, setCounters] = useState([0, 0, 0, 0]);
    const [init, setInit] = useState(true);

    const events = [
        // {
        //     name: "Akhand Paath",
        //     date: "Friday 3rd - Sunday 5th,  May 2022",
        //     time: ""
        // },
        // {
        //     name: "Kurmai (Engagement)",
        //     date: "Sunday 5th, May 2022",
        //     time: "12:00pm"
        // },
        {
            name: "Mehendi & Sangeet",
            date: "Thursday 26, May  2022",
            time: "7:00pm"
        },
        {
            name: "Haldi & Churra",
            date: "Friday 27, May 2022",
            time: "12:00pm"
        },
        {
            name: "Anand Karaj (Wedding)",
            date: "Saturday 28, May 2022",
            time: "10:00am"
        },
        {
            name: "Reception",
            date: "Sunday 29, May 2022",
            time: "5:00pm"
        },
    ];

    const add = index => {
        let c = [...counters];
        c[index]++;
        if (c[index] > 20) {
            c[index] = 20;
        }
        setCounters(c);
    }

    const minus = index => {
        let c = [...counters];
        c[index]--;
        if (c[index] < 0) c[index] = 0;
        setCounters(c);
    }

    useEffect(() => {
        if (init) {
            setInit(false);
        } else {
            onChange(counters);
        }
    }, [counters]);




    return (
        <div className="em-container">
            <h3>Please Enter expected number of people (including children) attending each event</h3>
            {events.map((item, index) => {
                return (
                    <div className="selectable" key={index}>
                        <p className="head">{item.name}</p>
                        <p className="tail">{item.date}</p>
                        <p className="tail">{item.time}</p>
                        <div className="counter">
                            <FiMinus onClick={() => minus(index)} />
                            <span>{counters[index]}</span>
                            <GrFormAdd onClick={() => add(index)} />

                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default EventManager;